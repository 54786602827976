import { NXGetLocale } from '@bootstrap';
import vClickOutside from 'v-click-outside';
import { MODAL_TYPES, MODAL_TEXT_KEYS } from '@constants/modals';
import VUE_EVENTS from '@constants/events';

export default {
    name: 'Modal',
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: {
        type: {
            type: String,
            default: 'error',
        },
        visible: {
            type: Boolean,
            default: false,
        },
        content: {
            type: String,
            default: '',
        },
        closeClickOut: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isVisible: this.visible,
        };
    },
    computed: {
        isTypeError() {
            return this.type === MODAL_TYPES.ERROR;
        },
        isTypeLegal() {
            return this.type === MODAL_TYPES.LEGAL;
        },
        modalTextTitle() {
            if (Object.values(MODAL_TYPES).includes(this.type)) {
                return NXGetLocale(MODAL_TEXT_KEYS[this.type.toUpperCase()]);
            } else {
                return NXGetLocale(MODAL_TEXT_KEYS.ERROR);
            }
        },
        modalTextButton() {
            return NXGetLocale(MODAL_TEXT_KEYS.OK);
        },
        modalClassObject() {
            return 'modal_' + MODAL_TYPES[this.type.toUpperCase()];
        }
    },
    methods: {
        handleCloseClicked() {
            this.$emit(VUE_EVENTS.MODAL_CLOSE);
        },
        handleClickOutside() {
            if (this.closeClickOut) {
                this.handleCloseClicked();
            }
        },
    },
};
