import policy from '../../../../mixins/policy';

export default {
    name: 'PopupLegal',
    mixins: [policy],
    props: {
        placement: {
            type: String,
            default: 'bottom-start'
        },
        flip: {
            type: Boolean,
            default: true
        },
        offset: {
            type: Array,
            default: () => [-10, 10],
        }
    },
    data() {
        return {
            isActive: false,
            textLegal: window.NXGetLocale('terms_legal'),
        };
    },
    computed: {
        policyLinks() {
            return [
                {
                    text: window.NXGetLocale('cookie_policy'),
                    value: this.linkPolicy,
                },
                {
                    text: window.NXGetLocale('privacy'),
                    value: 'https://www.nexters.com/tr/privacy',
                    external: true,
                },
                {
                    text: window.NXGetLocale('terms'),
                    value: 'https://www.nexters.com/tr/tos',
                    external: true,
                },
                {
                    text: window.NXGetLocale('parents_guide'),
                    value: this.linkParentsGuide,
                },
                {
                    text: window.NXGetLocale('community_rules'),
                    value: this.linkCommunityRules,
                },
                {
                    text: window.NXGetLocale('fan_content'),
                    value: this.linkFanContent,
                },
            ];
        },
    }
};
