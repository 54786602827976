// список всех возможных значений аргументов смотреть тут
// https://popper.js.org/docs/v2/constructors/

import vClickOutside from 'v-click-outside';
import { createPopper } from '@popperjs/core';

export default {
    name: 'PopupWrapper',
    model: {
        prop: 'showBody',
        event: 'change',
    },
    props: {
        placement: {
            type: String,
            default: 'bottom-end'
        },
        flip: {
            type: Boolean,
            default: true
        },
        showBody: {
            type: Boolean,
            default: false,
        },
        offset: {
            type: Array,
            default: () => [0, 10],
        }
    },
    data() {
        return {
            popperInstance: null,
        };
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    mounted() {
        if (!this.$refs.toggle || !this.$refs.body) return;
        this.popperInstance = createPopper(this.$refs.toggle, this.$refs.body, {
            placement: this.placement,
            modifiers: [
                {
                    name: 'flip',
                    enabled: this.flip,
                },
                {
                    name: 'offset',
                    options: {
                        offset: this.offset
                    },
                },
            ],
        });
    },
    beforeDestroy() {
        if (this.popperInstance) {
            this.popperInstance.destroy();
        }
    },
    computed: {
        isAngleAuto() {
            return this.isAngleInVariantsArray('auto', this.placement);
        },
        isAngleTop() {
            return this.isAngleInVariantsArray('top', this.placement);
        },
        isAngleBottom() {
            return this.isAngleInVariantsArray('bottom', this.placement);
        },
        isAngleRight() {
            return this.isAngleInVariantsArray('right', this.placement);
        },
        isAngleLeft() {
            return this.isAngleInVariantsArray('left', this.placement);
        },
        angleClassObject() {
            return {
                'popup__angle_auto': this.isAngleAuto,
                'popup__angle_top': this.isAngleTop,
                'popup__angle_bottom': this.isAngleBottom,
                'popup__angle_right': this.isAngleRight,
                'popup__angle_left': this.isAngleLeft,
            };
        },
    },
    methods: {
        isAngleInVariantsArray(prefix, placement) {
            return [`${prefix}`, `${prefix}-start`, `${prefix}-end`].includes(placement);
        },
        openBody() {
            this.$emit('change', true);
            this.popperInstance.update();
        },
        closeBody() {
            this.$emit('change', false);
        },
        toggleBody() {
            if (this.showBody) {
                this.closeBody();
            } else {
                this.openBody();
            }
        },
    }
};
