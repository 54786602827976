const CLICK_BUTTONS = {
    login: 'login',
    terms: 'terms',
    social: 'social',
    privacy: 'privacy',
    register: 'register',
    langChange: 'langChange',
    menuLogOut: 'menuLogOut',
    menuProfile: 'menuProfile',
    regFormNext: 'regFormNext',
    regFormPlay: 'regFormPlay',
    nicknameMenu: 'nicknameMenu',
    authFormLogin: 'authFormLogin',
    currencyChange: 'currencyChange',
    nicknameSignup: 'nicknameSignup',
    passwordChange: 'passwordChange',
    menuEmailConfirm: 'menuEmailConfirm',
    authFromPasRemind: 'authFromPasRemind',
};

export { CLICK_BUTTONS };
