import { MODAL_TYPES } from '@constants/modals';
import * as COOKIES from '../../views/policy/include/cookies.html';

require('../libs/functions');

export default {
    data() {
        return {
            cookieAlertVisible: false,
            cookieTimeout: 2592000000,
        };
    },
    mounted() {
        if (window.NXF.GetCookie('cookie_alert_accepted') !== 'yes') {
            this.cookieAlertVisible = true;
        }
    },
    methods: {
        acceptCookieDisclaimer() {
            this.cookieAlertVisible = false;
            window.NXF.SetCookie('cookie_alert_accepted', 'yes', this.cookieTimeout);
            this.sendButtonClicks && this.sendButtonClicks('cookieOk');
        },
        cookieMoreInfo() {
            this.sendButtonClicks && this.sendButtonClicks('cookieMoreinfo');
            this.modalOpen && this.modalOpen({
                type: MODAL_TYPES.LEGAL,
                content: COOKIES,
            });
        },
    }
};
