const PASSWORD_ERRORS = {
    APLHANUMERIC: '8_30_alphanumeric_characters',
    DO_NOT_MATCH: 'passwords_do_not_match',
    UNIQUE: 'old_and_new_pass_should_not_match',
};

const PASSWORD_RULES = {
    MIN_LENGTH: 8,
    MAX_LENGTH: 30,
};

export { PASSWORD_RULES, PASSWORD_ERRORS };
