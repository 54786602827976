export const REG_MODES = {
    email: 'email',
    password: 'password',
};
export const FORM_MODES = {
    auth: 'authentication',
    registration: 'registration',
    recovery: 'recovery',
};
export const AUTO_REGISTRATION_REGEXP = /[?&]reg=true/ig;
