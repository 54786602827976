const MODAL_TYPES = {
    ERROR: 'error',
    LEGAL: 'legal',
};

const MODAL_TEXT_KEYS = {
    OK: 'ok',
    INFO: 'information',
    ERROR: 'something_went_wrong',
};

export { MODAL_TYPES, MODAL_TEXT_KEYS };
