import { POLICY_PATH } from '../constants/policy';

const policy = {
    data() {
        return {
            userLanguage: window.NXUserInfo?.language || 'en'
        };
    },
    // dirty fix
    mounted() {
        const feedback = window.feedback || (window.feedback = {});
        const termsController = feedback.termsController || (feedback.termsController = {});
        termsController.pageShowById || (termsController.pageShowById = (termType) => {
            let linkHref = null;
            switch (termType) {
                case 'link_terms': linkHref = this.linkTerms; break;
                case 'link_policy': linkHref = this.linkPrivacy; break;
                default: return;
            }
            window.location.href = linkHref;
        });
    },
    computed: {
        policySubLanguage() {
            return (this.userLanguage === 'ru') ? 'ru' : 'en';
        },
        linkCommunityRules() {
            return `${POLICY_PATH.BASE}/${POLICY_PATH.COMMUNITY_RULES}/${this.policySubLanguage}`;
        },
        linkFanContent() {
            return `${POLICY_PATH.BASE}/${POLICY_PATH.FAN_CONTENT}/${this.policySubLanguage}`;
        },
        linkParentsGuide() {
            return `${POLICY_PATH.BASE}/${POLICY_PATH.PARENTS_GUIDE}/${this.policySubLanguage}`;
        },
        linkPrivacy() {
            return `${POLICY_PATH.BASE}/${POLICY_PATH.PRIVACY}/${this.policySubLanguage}`;
        },
        linkPrivacyAnnex() {
            return `${POLICY_PATH.BASE}/${POLICY_PATH.PRIVACY_ANNEX}/${this.policySubLanguage}`;
        },
        linkPolicy() {
            return `${POLICY_PATH.BASE}/${POLICY_PATH.COOKIES}/${this.policySubLanguage}`;
        },
        linkTerms() {
            return `${POLICY_PATH.BASE}/${POLICY_PATH.TERMS}/${this.policySubLanguage}`;
        }
    }
};

export default policy;
