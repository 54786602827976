import Vue from 'vue';
import SvgVue from 'svg-vue';

import { Sentry } from './modules/sentry.js';

window.NXGetLocale = function(key) {
    if (window.NXAppInfo && window.NXAppInfo.current_environment === 'production') {
        return (window.NXLocales && window.NXLocales[key]) || key;
    }

    return (window.NXLocales && window.NXLocales[key]) || 'NOT_FOUND_TRANSLATE_KEY: ' + key;
};

const NXGetLocale = window.NXGetLocale;

const regexpValidMail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

Vue.use(SvgVue);

Vue.mixin({
    data() {
        return {
            NXAppInfo: window.NXAppInfo,
            NXUserInfo: window.NXUserInfo,
            NXFlashVars: window.NXFlashVars,
            NXSupportSettings: window.NXSupportSettings,
        };
    },
});

window.axios = require('axios');

const Api = {
    get: function(url, params) {
        return new Promise(
            (resolutionFunc, rejectionFunc) => {
                window.axios.get(url, { params })
                    .then(response => {
                        if (!response.data) {
                            rejectionFunc(response);
                        } else {
                            resolutionFunc(response.data);
                        }
                    })
                    .catch(e => {
                        console.error(e);
                    });
            },
            () => {}
        );
    },
    post: function(url, data) {
        return new Promise(
            (resolutionFunc, rejectionFunc) => {
                window.axios.post(url, data, {
                    responseType: 'json',
                    headers: {
                        accept: 'application/json',
                    },
                }).then(response => {
                    resolutionFunc(response.data);
                }).catch(error => {
                    if (error?.response) {
                        if (error.response.status < 400 || error.response.status >= 500) {
                            Sentry.captureException(error);
                        }
                        rejectionFunc(error.response);
                    } else {
                        rejectionFunc(error);
                    }
                });
            },
            () => {}
        );
    }
};

export { Api, NXGetLocale, regexpValidMail };
