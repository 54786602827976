'use strict';
// внутренние функции фронтенда

// глобальный объект фрейма
window.NXF = window.NXF || {};

window.NXF.SetCookie = function(name, value, time) {
    time = time || 77777777777;
    value = value || '';
    let date = new Date(+(new Date()) + time);
    // закомментировано для разработки на локалхосте
    document.cookie = name + '=' + value + '; path=/; expires=' + date.toUTCString();// + '; SameSite=None; Secure';
};
window.NXF.GetCookie = function(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
};
window.NXF.EraseCookie = function(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
