const POLICY_PATH = {
    BASE: '/policy',
    COMMUNITY_RULES: 'community_rules',
    FAN_CONTENT: 'fan_content',
    PARENTS_GUIDE: 'parents_guide',
    PRIVACY: 'privacy',
    PRIVACY_ANNEX: 'privacy_annex',
    TERMS: 'terms',
    COOKIES: 'cookies'
};

export { POLICY_PATH };
