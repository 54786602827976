import { Api, NXGetLocale, regexpValidMail } from '@bootstrap';
import { socialAuth, socialsResults, socialsList } from '@libs/socials';

import { MODAL_TYPES } from '@constants/modals';
import { CLICK_BUTTONS } from '@constants/logs';
import { REG_MODES, FORM_MODES, AUTO_REGISTRATION_REGEXP } from '@constants/auth';

import modalEngine from '@mixins/modal';
import passwordValidator from '@mixins/passwordValidator';
import logs from '@mixins/logs';
import cookie from '@mixins/cookie';

import * as DOCUMENT_TERMS_EN from '../../../views/policy/include/terms_en.html';
import * as DOCUMENT_TERMS_RU from '../../../views/policy/include/terms_ru.html';
import * as DOCUMENT_PRIVACY_EN from '../../../views/policy/include/privacy_en.html';
import * as DOCUMENT_PRIVACY_RU from '../../../views/policy/include/privacy_ru.html';

export default {
    name: 'Auth',
    mixins: [modalEngine, passwordValidator, logs, cookie],
    data() {
        const formMode = AUTO_REGISTRATION_REGEXP.test(window.location.search)
            ? FORM_MODES.registration
            : FORM_MODES.auth;

        return {
            formMode,
            locale: window.NXUserInfo.language || 'en',

            email: '',
            password: '',
            remember: 1,

            emailError: '',
            passwordError: '',
            emailNotFound: false,

            disabledButton: false,

            regMode: REG_MODES.email,
            agreement: false,

            agreementText: '',
            resetButtonText: '',

            regModes: REG_MODES,

            authFormFields: {
                email: 'email',
                password: 'password',
            },
            formModes: FORM_MODES,
            emailErrors: {
                empty: '',
                incorrectEmail: NXGetLocale('incorrect_e_mail'),
                alreadyExist: NXGetLocale('reg_email_already_exists'),
                unknownError: NXGetLocale('unknown_error'),
                userNotFound: NXGetLocale('user_not_found'),
                throttledRecovery: NXGetLocale('throttled_recovery'),
            },
            passwordErrors: {
                empty: '',
                incorrectPassword: NXGetLocale('incorrect_password'),
                rules: NXGetLocale('8_30_alphanumeric_characters'),
            },

            socialsList,
            socialsResults,
            socialsLoading: {
                google: false,
                facebook: false,
                vkontakte: false,
                odnoklassniki: false,
            },
            generalMessagesLastIndex: 0,

            isVkModalOpen: false
        };
    },
    mounted() {
        window.dataLayer.push({ 'event': 'viewContent' });

        window.onload = this.resize;
        window.onresize = this.resize;

        this.sendLandingLoaded();
        this.sendAdBlockOn();
    },
    watch: {
        agreement() {
            this.clearErrors();
        },
    },
    computed: {
        isRegModePassword() {
            return this.regMode === this.regModes.password;
        },
        isRegModeEmail() {
            return this.regMode === this.regModes.email;
        },
        disabledButtonClass() {
            if (
                this.formMode === this.formModes.registration
                && this.isRegModePassword
            ) {
                return { 'disabled': !this.agreement || this.disabledButton };
            } else if (this.isRegModeEmail) {
                return { 'disabled': this.disabledButton };
            }
        },
        isRuLocale() {
            return this.locale === 'ru';
        }
    },
    methods: {
        resize() {
            document.body.style.height = window.innerHeight + 'px';
        },
        changeFormMode(mode) {
            this.regMode = this.regModes.email;
            this.formMode = mode;

            // сбрасываем ошибки
            this.emailError = this.emailErrors.empty;
            this.passwordError = this.passwordErrors.empty;

            // активируем кнопки обратно
            this.disabledButton = false;
            // вешаем фокус на строку почты
            this.$refs.email && this.$refs.email.focus();

            if (mode === this.formModes.registration) {
                this.sendButtonClicks(CLICK_BUTTONS.register);
            } else if (mode === this.formModes.auth) {
                this.sendButtonClicks(CLICK_BUTTONS.login);
            } else if (mode === this.formModes.recovery) {
                this.sendButtonClicks(CLICK_BUTTONS.authFromPasRemind);
            }
        },
        // метод не используется. Создать задачу для рефактора
        checkLoginFields(fieldName) {
            if (this.emailError !== this.emailErrors.empty && fieldName === this.authFormFields.email) {
                this.checkEmailField();
            }
            if (this.passwordError !== this.passwordErrors.empty && fieldName === this.authFormFields.password) {
                this.checkPasswordField();
            }
        },
        clickLogin() {
            if (!this.checkValidMail()) {
                this.emailError = this.emailErrors.incorrectEmail;
                this.disabledButton = true;
            }

            let validationResult = this.checkValidPass(this.password);
            let isValidPassword = validationResult.result;

            if (!isValidPassword) {
                this.passwordError = validationResult.message;
                this.disabledButton = true;
            }

            if (this.disabledButton) return;

            this.disabledButton = true;

            const loginRequest = () => {
                Api.post('/login', {
                    login: this.email,
                    password: this.password,
                    remember: (this.remember ? 1 : 0)
                }).then(result => {
                    document.location.reload();
                }).catch(result => {
                    // todo поговорить с бекендом на счёт упрощения данной конструкции
                    let message = result?.data?.message;
                    if (message === this.emailErrors.userNotFound) {
                        this.emailError = message + '!';
                        this.emailNotFound = true;
                    } else if (message === this.passwordErrors.incorrectPassword) {
                        this.passwordError = message;
                    } else if (message.password && message.password[0]) {
                        this.passwordError = NXGetLocale(message.password[0]);
                    } else if (message.email && message.email[0]) {
                        this.emailError = NXGetLocale(message.email[0]);
                    } else {
                        this.emailError = message;
                    }
                });
            };

            this.sendButtonClicks(CLICK_BUTTONS.authFormLogin)
                .finally(() => {
                    loginRequest();
                });
        },
        clickReset() {
            this.emailError = this.emailErrors.empty;

            if (!this.checkValidMail()) {
                this.emailError = this.emailErrors.incorrectEmail;
                this.disabledButton = true;
            }

            if (this.disabledButton) return;

            this.disabledButton = true;

            this.resetButtonText = NXGetLocale('resend');

            Api.post('/password/email', {
                login: this.email,
            }).then(result => {
                this.agreementText = NXGetLocale('the_letter_has_been_sent');
                this.resetButtonText = NXGetLocale('resend');

                setTimeout(() => {
                    this.disabledButton = false;
                }, 5000);
            }).catch(result => {
                const errorKey = result?.data?.message?.login[0];
                this.emailError = errorKey ? NXGetLocale(errorKey) : this.emailErrors.unknownError;

                this.disabledButton = false;
                this.emailNotFound = true;
            });
        },
        clickPlay() {
            let validationResult = this.checkValidPass(this.password);
            let isValidPassword = validationResult.result;

            if (!isValidPassword) {
                this.passwordError = validationResult.message;
                this.disabledButton = true;
            }

            if (!this.agreement) {
                this.disabledButton = true;
            }

            if (this.disabledButton) return;

            this.disabledButton = true;

            const registerRequest = () => {
                Api.post('/register', {
                    login: this.email,
                    password: this.password,
                    agreement: this.agreement,
                }).then(result => {
                    document.location.reload();
                }).catch(result => {
                    this.passwordError = this.passwordErrors.rules;
                });
            };

            this.sendButtonClicks(CLICK_BUTTONS.regFormPlay)
                .finally(() => {
                    registerRequest();
                });
        },
        clickNext() {
            this.sendButtonClicks(CLICK_BUTTONS.regFormNext);

            let isValidEmail = this.checkValidMail();

            if (!isValidEmail) {
                this.emailError = this.emailErrors.incorrectEmail;
                this.disabledButton = true;
            }

            if (this.disabledButton) return;

            this.disabledButton = true;

            Api.post('/check_exist_user', {
                login: this.email
            }).then(result => {
                this.emailError = this.emailErrors.empty;
                this.regMode = this.regModes.password;
                this.agreement = true;
                this.disabledButton = false;
            }).catch(result => {
                this.emailError = this.emailErrors.alreadyExist;
            });
        },
        socialStart(name) {
            this.sendButtonClicks(`${CLICK_BUTTONS.social} ${name}`);

            if (!name) return;
            if (this.socialsLoading[name]) return;

            this.socialsLoading[name] = true;

            socialAuth(name)
                .then(result => {
                    location.reload();
                    this.socialsLoading[result.name] = false;
                })
                .catch(result => {
                    this.socialsLoading[result.name] = false;
                });
        },
        checkEmailField() {
            if (!this.checkValidMail()) {
                this.emailError = this.emailErrors.incorrectEmail;
            } else {
                this.emailError = this.emailErrors.empty;
            }
        },
        clearErrors(field) {
            this[field] = '';
            if (field === 'emailError') {
                this.emailNotFound = false;
            }
            this.disabledButton = false;
        },
        checkValidMail() {
            let prepared = String(this.email.toLowerCase());
            return regexpValidMail.test(prepared);
        },
        clickTerms() {
            this.sendButtonClicks(CLICK_BUTTONS.terms);
            const DOCUMENT_TEXT = (this.locale === 'ru') ? DOCUMENT_TERMS_RU : DOCUMENT_TERMS_EN;
            this.modalOpen({ type: MODAL_TYPES.LEGAL, content: DOCUMENT_TEXT });
        },
        clickPolicy() {
            this.sendButtonClicks(CLICK_BUTTONS.privacy);
            const DOCUMENT_TEXT = (this.locale === 'ru') ? DOCUMENT_PRIVACY_RU : DOCUMENT_PRIVACY_EN;
            this.modalOpen({ type: MODAL_TYPES.LEGAL, content: DOCUMENT_TEXT });
        },
        openVkModal() {
            this.isVkModalOpen = true;
        },
        closeVkModal() {
            this.isVkModalOpen = false;
        }
    },
};
