import { MODAL_TYPES } from '@constants/modals';

const modalEngine = {
    data() {
        return {
            modal: {
                type: MODAL_TYPES.ERROR,
                visible: false,
                content: '',
            },
        };
    },
    methods: {
        modalSetContent(content = '') {
            this.modal.content = content;
        },
        modalSetType(type = MODAL_TYPES.ERROR) {
            this.modal.type = type;
        },
        modalClose() {
            this.modalSetContent('');
            this.modal.visible = false;
        },
        modalOpen({ type = MODAL_TYPES.ERROR, content = '' } = {}) {
            this.modalSetContent(content);
            this.modalSetType(type);
            this.modal.visible = true;
        },
    }
};

export default modalEngine;
