import { Api } from '@bootstrap';

import logs from '@mixins/logs';

import TOGGLE_BUTTON_STYLE from '@constants/toggleButton';
import { CLICK_BUTTONS } from '@constants/logs';

export default {
    name: 'PopupLanguage',
    mixins: [logs],
    props: {
        buttonText: {
            type: String,
            default: ''
        },
        buttonStyle: {
            type: String,
            default: TOGGLE_BUTTON_STYLE.LIGHT
        },
        placement: {
            type: String,
            default: 'auto'
        },
        flip: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            languages: {
                en: 'EN - english',
                ru: 'RU - русский',
                es: 'ES - español',
                de: 'DE - deutsch',
                it: 'IT - italiano',
                fr: 'FR - français',
                pt: 'PT - português',
                jp: 'JP - 日本語',
                ko: 'KO - 한국어',
            },
            isActive: false,
            globeSvgPath: window?.NXAppInfo?.asset_url + 'images/common/svg/globe.svg',
        };
    },
    computed: {
        toggleStyleObject() {
            return `button_${this.buttonStyle}`.toLowerCase();
        },
        formMode() {
            return this.$parent.formMode;
        },
        toggleButtonText() {
            const language = this.NXUserInfo?.language;
            return `${language.toUpperCase()} - ${this.buttonText}`;
        }
    },
    methods: {
        changeLanguage(language) {
            const localeRequest = () => {
                Api.post('/locale', {
                    language
                }).then((result) => {
                    document.location.reload();
                }).catch(result => {
                    console.error(result);
                });
            };

            this.sendButtonClicks(`${CLICK_BUTTONS.langChange}_${language}`)
                .finally(() => {
                    localeRequest();
                });
        }
    }
};
