import Vue from 'vue';

require('./bootstrap');

Vue.component('Auth', require('./components/Auth/Auth.vue').default);
Vue.component('Modal', require('./components/Common/Modal/Modal.vue').default);
Vue.component('PopupLegal', require('./components/Common/Popup/PopupLegal/PopupLegal.vue').default);
Vue.component('PopupWrapper', require('./components/Common/Popup/PopupWrapper/PopupWrapper.vue').default);
Vue.component('PopupLanguage', require('./components/Common/Popup/PopupLanguage/PopupLanguage.vue').default);

// eslint-disable-next-line no-unused-vars
const app = new Vue({
    el: '#app',
});
