import { Api } from '@bootstrap';
import { UAParser } from 'ua-parser-js';

// get info for logs
const uaParser = new UAParser();

const logsBody = {
    adBlockOn: !window.canRunAds,
    referrer: (window.NXAppInfo && window.NXAppInfo.nx_source) || '',
    os: uaParser.getOS().name,
    browser: uaParser.getBrowser().name,
    browserVersion: uaParser.getBrowser().version,
    resolution: `${window.screen.width}x${window.screen.height}`,
    deviceType: uaParser.getDevice().type || 'desktop',
    urlCustomParams: (window.NXAppInfo && window.NXAppInfo.url_custom_params) || '', // проверка подключения js_vars
    url: window.location.href,
};

const logs = {
    methods: {
        // функция по отправке инфы о свершенной регистрации
        sendFirstLogin(typeForDelayedRegister = null, email = '') {
            let params = Object.assign({}, logsBody);

            if (typeForDelayedRegister === 'email' && !email) {
                console.error('No "email" parameter for log sending');
                return;
            } else {
                params.email = email;
            }

            let neededRegType;

            if (typeForDelayedRegister) {
                neededRegType = typeForDelayedRegister;
            } else {
                neededRegType = 'delayedSignUp';
            }

            params.regType = neededRegType;

            Api.post('/log/account_registration', params);
        },
        // функция по отправке инфы об авторизации
        sendAuthCompleted() {
            let params = Object.assign({}, logsBody);
            params.email = window.NXUserInfo.email;

            Api.post('/log/auth_completed', params);
        },
        // функция по отправке инфы о загрузке страницы в логи
        sendLandingLoaded() {
            Api.post('/log/landing_loaded', logsBody);
        },
        // функция по отправке инфы о загрузке страницы в логи
        sendAdBlockOn() {
            if (!window.canRunAds) {
                Api.post('/log/ad_block_on');
            }
        },
        sendDelayedPopupLog(delayedSignUpAction, delayedSignUpType) {
            if (!delayedSignUpType) {
                console.error('No signUpType argument for log sending');
                return;
            }

            let isCorrectRecievedValues = (type, action) => {
                return [
                    'signUpProposal',
                    'signUpPreRequired',
                    'signUpRequired',
                    'signUpForPurchase',
                    'loginDelayed'
                ]
                    .some(item => item === type)
                && [
                    'regFormAppearance',
                    'registrationCompleted',
                    'regFromUser'
                ]
                    .some(item => item === action);
            };

            if (isCorrectRecievedValues(delayedSignUpType, delayedSignUpAction)) {
                return Api.post('/log/delayed_popup', {
                    delayedSignUpType,
                    delayedSignUpAction
                });
            } else {
                console.error('Invalid arguments for sending log');
            }
        },
        // функция по отправке кликов в логи
        sendButtonClicks(button = '') {
            return new Promise((resolve, reject) => {
                const windows = {
                    'confirm_email': 'confirmEmailPage',
                    'ResetPassword': 'changePasswordPage',
                    'profile': 'profile',
                    'link': 'profile',
                    'unlink': 'profile',
                    'game': 'gameScreen',
                    'playground': 'gameScreen',
                    'login': 'gameScreen',
                    'registration': 'landingPage',
                    'recovery': 'landingPage',
                    'authentication': 'landingPage',
                    'delayed_login': 'gameScreen',
                    'delayed_registration': 'gameScreen',
                    'community-rules': 'community-rules',
                    'community-rules-ru': 'community-rules-ru',
                    'parents-guide': 'parents-guide',
                    'parents-guide-ru': 'parents-guide-ru',
                    'policy': 'policy',
                    'privacy': 'privacy',
                    'privacy-ru': 'privacy-ru',
                    'terms': 'terms',
                    'terms-ru': 'terms-ru',
                    'privacy-annex': 'privacy-annex',
                    'privacy-annex-ru': 'privacy-annex-ru',
                    'cookies': 'cookies',
                    'cookies-annex': 'cookies-annex',
                    'unsubscribe': 'unsubscribe'
                };

                const mode = this.formMode || '';
                const buttonName = button;
                const windowName = windows[mode] || '';

                const requestBody = { buttonName, windowName };

                Api.post('/log/button_clicked', requestBody)
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        reject(new Error());
                    });
            });
        }
    }
};

export default logs;
