const socialsList = {
    google: 'google',
    facebook: 'facebook',
    vkontakte: 'vkontakte',
    odnoklassniki: 'odnoklassniki'
};
const socialLinks = {
    google: 'https://accounts.google.com/o/oauth2/v2/auth',
    facebook: 'https://www.facebook.com/v11.0/dialog/oauth',
    vkontakte: 'https://oauth.vk.com/authorize',
    odnoklassniki: 'https://connect.ok.ru/oauth/authorize',
};
const additionalParams = {
    google: '&scope=email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid',
    facebook: '&scope=email',
    vkontakte: '&scope=email',
    odnoklassniki: '&scope=email',
};
const socialsResults = {
    success: '',
    can_not_do_this: 'can_not_do_this',
    something_went_wrong: 'something_went_wrong',
    user_has_another_account: 'user_has_another_account',
    popup_was_closed_by_user: 'popup_was_closed_by_user',
    account_not_belong_to_user: 'account_not_belong_to_user',
    _you_have_one_way_to_enter_the_game: '_you_have_one_way_to_enter_the_game',
    cantUnlinkWhileOn: 'you_can_not_unlink_social_account_which_you_are_currently_signed_in_with',
};
const popupRecords = [];

window.addEventListener('message', onSocialAuthMessageHandler);
window.addEventListener('focus', closePopups);
window.addEventListener('beforeunload', closePopups);

function onSocialAuthMessageHandler(message) {
    const source = message.source;
    const data = message.data;
    if (!data?.nx) return;
    for (let popupRecord of popupRecords) {
        if (popupRecord.popup !== source) continue;
        popupRecord.complete(data);
    }
}
function closePopups() {
    popupRecords
        .splice(0, popupRecords.length)
        .forEach(closePopup);
}
function closePopup(popupRecord) {
    if (!popupRecord) return;
    const popup = popupRecord.popup;
    const observer = popupRecord.closeObserver;
    clearInterval(observer);
    if (!popup.closed) popup.close();
}
function socialAuth(name = '') {
    const origin = location.origin;
    const socialName = socialsList[name || ''] || '';
    const clientIdKey = `${socialName}_client_id`;
    const clientId = window.NXAppInfo[clientIdKey] || '';
    const redirectUrl = `${origin}/social/${socialName}/callback`;
    const socialLink = socialLinks[socialName] || '';
    const socialQueryParams = `?client_id=${clientId}&response_type=code&redirect_uri=${redirectUrl}`;
    const socialAdditionalParam = additionalParams[socialName] || '';
    const socialUrl = socialLink.concat(socialQueryParams, socialAdditionalParam);

    if (!socialName) {
        console.error('Incorrect social\'s name. Get it from socialsList');
        return;
    }
    if (!clientId) {
        console.error(`Empty client_id/app_id in window.NXAppInfo.${name}_client_id`);
        return;
    }

    return new Promise((resolve, reject) => {
        const popupRecord = {
            popup: window.open(socialUrl, socialName, 'width=800,height=480'),
            observer: setInterval(popupCloseObserver, 100),
            complete: socialAuthComplete,
            dispose: disposePopup
        };
        const socialAuthRequestResult = {
            name: socialName,
            message: socialsResults.popup_was_closed_by_user
        };

        popupRecords.push(popupRecord);

        function popupCloseObserver() {
            const popup = popupRecord.popup;
            if (popup?.closed ?? false) disposePopup();
        }
        function socialAuthComplete(data) {
            const secret = 'social.message: ';
            const error = data.error && ~data.error.indexOf(secret)
                ? data.error.slice(secret.length)
                : data.error;
            const actionType = data.actionType;

            closePopup(popupRecord);

            socialAuthRequestResult.message = error || socialsResults.success;
            socialAuthRequestResult.actionType = actionType;

            error
                ? reject(socialAuthRequestResult)
                : resolve(socialAuthRequestResult);
        }
        function disposePopup() {
            reject(socialAuthRequestResult);
        }
    });
}

export { socialsList, socialsResults, socialAuth };
