import { NXGetLocale } from '@bootstrap';

import { PASSWORD_RULES, PASSWORD_ERRORS } from '@constants/password';

const passwordValidator = {
    methods: {
        validatePasswordReset(password, passwordConfirm) {
            let { result, message } = this.checkValidPass(password);
            if (!result) return { result, message };
            return this.checkConfirmPass(password, passwordConfirm);
        },
        validatePasswordChange(passwordOld, passwordNew, passwordNewConfirm) {
            let { result, message } = this.checkValidPass(passwordOld);
            if (!result) return { result, message };
            ({ result, message } = this.checkValidPass(passwordNew));
            if (!result) return { result, message };
            ({ result, message } = this.checkConfirmPass(passwordNew, passwordNewConfirm));
            if (!result) return { result, message };
            return this.checkIsNewPassUnique(passwordOld, passwordNew);
        },
        checkValidPass(password) {
            let result = true;
            let message = '';
            if (!password
                || (password.length < PASSWORD_RULES.MIN_LENGTH)
                || (password.length > PASSWORD_RULES.MAX_LENGTH)
            ) {
                result = false;
                message = NXGetLocale(PASSWORD_ERRORS.APLHANUMERIC);
            }
            return { result, message };
        },
        checkConfirmPass(password1, password2) {
            let result = true;
            let message = '';
            if (password1 !== password2) {
                result = false;
                message = NXGetLocale(PASSWORD_ERRORS.DO_NOT_MATCH);
            }
            return { result, message };
        },
        checkIsNewPassUnique(oldPassword, newPassword) {
            let result = true;
            let message = '';
            if (newPassword === oldPassword) {
                result = false;
                message = NXGetLocale(PASSWORD_ERRORS.UNIQUE);
            }
            return { result, message };
        },
        parseBadApiPasswordRequest(serverResponse) {
            // TODO переделать это, когда будет реализована задача на тех.долг ID-5209
            return Object.entries(serverResponse.data.message)[0].reverse()[0][0];
        }
    }
};

export default passwordValidator;
