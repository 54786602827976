import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

const isSentryLoggingActive = window.NXAppInfo?.current_environment !== 'local';

const ignoreErrorsLists = {
    title: [
        'Start.js', // ошибки из игры
        'extension',
        'Extension', // из пользовательских расширений
    ],
    description: [
        'Non-Error',
        'AbortError: The operation was aborted', // Firefox Dev edition
        'No error',
        'Extension',
        'extension',
        'Unexpected token \'<\'', // летят из метрик
        'can\'t redefine non-configurable property "userAgent"', // firefox'у не нравится редифайн
        'illegal character U+009E',
        'out of memory',
        'Cannot read property \'config\' of undefined',
        'InvalidStateError: Failed to execute \'transaction\' on \'IDBDatabase\': The database connection is closing.', // не аффектящая ошибка в Safari, возникает из-за устаревшего пакета
        'getComponent' // ошибка 'getComponent of null' из файла Start.js
    ]
};

isSentryLoggingActive && Sentry.init({
    Vue,
    dsn: window.NXAppInfo?.sentry_front_dsn,
    integrations: [new Integrations.BrowserTracing()],
    beforeSend(event, hint) {
        const description = hint?.originalException?.stack;

        let canSendEvent = true;
        if (description) {
            ignoreErrorsLists.title.forEach((title) => {
                if (description.indexOf(title) !== -1) {
                    canSendEvent = false;
                }
            });
        }

        return canSendEvent ? event : undefined;
    },
    environment: window.NXAppInfo?.current_environment,
    ignoreErrors: ignoreErrorsLists.description,
});

export { Sentry };
